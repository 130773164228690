import React from "react";
import { notify } from "../utils/notifications";
import Button from "@material-ui/core/Button";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const CopyableDisplay = ({ text }: { text: string }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    notify({ message: "Copied!" });
  };

  return (
    <>
      <Button onClick={copyToClipboard}>
        <FileCopyIcon style={{ fontSize: 24, color: "white" }} />
      </Button>
    </>
  );
};

export default CopyableDisplay;
