import React, { useState } from "react";
import {
  Grid,
  InputLabel,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { notify } from "../utils/notifications";
import { useConnection } from "../utils/connection";
import { useWallet } from "../utils/wallet";
import {
  TOKEN_VESTING_PROGRAM_ID,
  createChangeDestinationInstruction,
} from "@bonfida/token-vesting";
import Spin from "../components/Spin";
import WalletConnect from "../components/WalletConnect";
import { PublicKey, Transaction } from "@solana/web3.js";
import { sendTransaction } from "../utils/send";

const useStyles = makeStyles({
  submitBut: {
    color: "white",
    fontWeight: 600,
    background: "linear-gradient(213.67deg, #7171ff -3.51%, #DC1FFF 99.6%)",
    borderRadius: 5,
    height: "50px",
    fontSize: 30,
    padding: 30,
    margin: "5%",
  },
});

const ChangeDestinationPage = () => {
  const classes = useStyles();
  const connection = useConnection();
  const { wallet, connected } = useWallet();
  const [loading, setLoading] = useState(false);
  const [seed, setSeed] = useState<string | null>(null);
  const [currentDestination, setCurrentDestination] =
    useState<string | null>(null);
  const [newDestination, setNewDestination] = useState<string | null>(null);

  const onChangeSeed = (e) => {
    setSeed(e.target.value.trim());
  };

  const onChangeNewDestination = (e) => {
    setNewDestination(e.target.value.trim());
  };

  const onChangeCurrentDestination = (e) => {
    setCurrentDestination(e.target.value.trim());
  };

  const onClick = async () => {
    if (!seed || !newDestination || !currentDestination) {
      notify({
        message: `Please enter seed and destination`,
        variant: "error",
      });
      return;
    }
    try {
      const _currentDestination = new PublicKey(currentDestination);
      const _newDestination = new PublicKey(newDestination);
      setLoading(true);
      // Check mints
      const currentInfo = await connection.getParsedAccountInfo(
        _currentDestination
      );
      const newInfo = await connection.getParsedAccountInfo(_newDestination);
      console.log("newInfo", newInfo);
      if (
        // @ts-ignore
        newInfo.value?.data?.length === 0 ||
        // @ts-ignore
        currentInfo.value?.data.parsed.info.mint !==
          // @ts-ignore
          newInfo.value?.data.parsed.info.mint
      ) {
        return notify({ message: "Mints do not match", variant: "error" });
      }

      let seedWord = Buffer.from(seed, "hex").slice(0, 31);
      const [vestingAccountKey, bump] = await PublicKey.findProgramAddress(
        [seedWord],
        TOKEN_VESTING_PROGRAM_ID
      );
      seedWord = Buffer.from(
        seedWord.toString("hex") + bump.toString(16),
        "hex"
      );

      const instructions = createChangeDestinationInstruction(
        TOKEN_VESTING_PROGRAM_ID,
        vestingAccountKey,
        wallet.publicKey,
        _currentDestination,
        _newDestination,
        [seedWord]
      );

      const tx = new Transaction().add(instructions);

      await sendTransaction({
        transaction: tx,
        wallet: wallet,
        connection: connection,
      });
    } catch (err) {
      console.warn(`Error changing destination - ${err}`);
      notify({
        message: `Error changing destination - ${err}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!connected) {
    return (
      <>
        <Grid container justify="center">
          <WalletConnect />
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={5}
        direction="column"
      >
        <Grid item>
          <FormControl>
            <InputLabel shrink>Contract Seed:</InputLabel>
            <TextField
              value={seed}
              onChange={onChangeSeed}
              placeholder="Contract Seed"
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel shrink>Current Destination (Token Address):</InputLabel>
            <TextField
              placeholder="Current Destination"
              value={currentDestination}
              onChange={onChangeCurrentDestination}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel shrink>New Destination (Token Address):</InputLabel>
            <TextField
              placeholder="New Destination"
              value={newDestination}
              onChange={onChangeNewDestination}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            disabled={loading}
            onClick={onClick}
            className={classes.submitBut}
          >
            {loading ? (
              <Spin size={20} />
            ) : (
              <span style={{ color: "white" }}>Submit</span>
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangeDestinationPage;
