import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { notify } from "../utils/notifications";
import { useConnection } from "../utils/connection";
import { useWallet } from "../utils/wallet";
import { sendTransaction } from "../utils/send";
import { Transaction, PublicKey } from "@solana/web3.js";
import Spin from "../components/Spin";
import {
  unlock,
  getContractInfo,
  ContractInfo,
  TOKEN_VESTING_PROGRAM_ID,
} from "@bonfida/token-vesting";
import { getContractKeyFromSeed, timeConverter } from "../utils/utils";
import GraphSection from "../components/Graph";
import WalletConnect from "../components/WalletConnect";
import { ExplorerLink } from "../components/Link";
import BN from "bn.js";

const useStyles = makeStyles({
  submitBut: {
    color: "white",
    fontWeight: 600,
    background: "linear-gradient(213.67deg, #7171ff -3.51%, #DC1FFF 99.6%)",
    borderRadius: 5,
    height: "50px",
    fontSize: 30,
    padding: 30,
    margin: "5%",
  },
  input: {
    color: "white",
  },
  inputLabel: {
    marginTop: 10,
    marginBottom: 10,
  },
  deleteIcon: {
    color: "white",
    marginTop: 30,
    cursor: "pointer",
  },
  datePicker: {
    color: "white",
    width: 250,
  },
  graphContainer: {
    height: "max(50vh, 200px)",
    width: "80%",
  },
  text: {
    color: "white",
    fontSize: 25,
    opacity: 0.8,
  },
});

const SearchContract = () => {
  const connection = useConnection();
  const classes = useStyles();
  const { wallet, connected } = useWallet();
  const [seed, setSeed] = useState<string | null>(null);
  const [contractInfo, setContractInfo] = useState<ContractInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [decimals, setDecimals] = useState(1);
  const [mint, setMint] = useState<PublicKey | null>(null);
  const [destination, setDestination] = useState<string | null>(null);

  const onChangeSeed = (e) => {
    setSeed(e.target.value.trim());
  };
  const onSearch = async () => {
    if (!seed) {
      notify({
        message: "Invalid seed",
        variant: "error",
      });
      return;
    }
    try {
      setLoading(true);
      let vestingAccountKey: PublicKey;
      try {
        vestingAccountKey = new PublicKey(seed);
      } catch {
        vestingAccountKey = await getContractKeyFromSeed(
          Buffer.from(seed, "hex")
        );
      }

      const _contractInfo = await getContractInfo(
        connection,
        vestingAccountKey
      );
      const decimals = (
        await connection.getTokenSupply(_contractInfo.mintAddress)
      ).value.decimals;
      setMint(_contractInfo.mintAddress);
      setDecimals(decimals);
      setContractInfo(_contractInfo);
      setDestination(_contractInfo.destinationAddress.toBase58());
    } catch (err) {
      console.warn(`Error loading contract info - ${err}`);
      notify({
        message: `Error loading contract info - ${err}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const onUnlock = async () => {
    if (!seed || !mint) {
      notify({
        message: "Invalid seed",
        variant: "error",
      });
      return;
    }
    try {
      setLoading(true);
      const instructions = await unlock(
        connection,
        TOKEN_VESTING_PROGRAM_ID,
        Buffer.from(seed, "hex"),
        mint
      );

      const tx = new Transaction().add(...instructions);

      await sendTransaction({
        transaction: tx,
        wallet: wallet,
        connection: connection,
      });
    } catch (err) {
      console.warn(`Error unlocking tokens - ${err}`);
      notify({
        message: `Error unlocking tokens - ${err}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!connected) {
    return (
      <>
        <Grid container justify="center">
          <WalletConnect />
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={5}
        direction="row"
      >
        <Grid item>
          <FormControl>
            <InputLabel shrink>Contract Seed:</InputLabel>
            <TextField
              placeholder="Contract Seed"
              value={seed}
              onChange={onChangeSeed}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Button
            disabled={loading}
            onClick={onSearch}
            className={classes.submitBut}
          >
            {loading ? (
              <Spin size={20} />
            ) : (
              <span style={{ color: "white" }}>Search</span>
            )}
          </Button>
        </Grid>
        {mint && (
          <Grid item>
            <Button
              disabled={loading}
              onClick={onUnlock}
              className={classes.submitBut}
            >
              {loading ? (
                <Spin size={20} />
              ) : (
                <span style={{ color: "white" }}>Unlock</span>
              )}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container justify="center">
        <div className={classes.graphContainer}>
          <GraphSection
            data={contractInfo?.schedules.map((s) => {
              const numerateur = new BN(s.amount.toString());
              const denominateur = new BN(Math.pow(10, decimals).toString());
              return {
                amount: numerateur.div(denominateur).toNumber(),
                time: timeConverter(new Date(s.releaseTime.toNumber() * 1000)),
              };
            })}
            xKey="time"
            yKey="amount"
          />
        </div>
      </Grid>
      {destination && (
        <Grid container justify="center" style={{ marginTop: "3%" }}>
          <Grid item>
            <Typography className={classes.text}>
              Current Destination:{" "}
              <ExplorerLink address={destination}>{destination}</ExplorerLink>
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const UnlockPage = () => {
  return (
    <>
      <SearchContract />
    </>
  );
};

export default UnlockPage;
