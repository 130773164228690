import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  ResponsiveContainer,
  Line,
} from "recharts";

const GraphSection = ({ data, xKey, yKey }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xKey} />
        <YAxis dataKey={yKey} />
        <Tooltip />
        <Line type="monotone" dataKey={xKey} stroke="#82ca9d" />
        <Line type="monotone" dataKey={yKey} stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GraphSection;
