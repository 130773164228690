import "date-fns";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import GraphSection from "../components/Graph";
import {
  create,
  Schedule,
  Numberu64,
  generateRandomSeed,
  TOKEN_VESTING_PROGRAM_ID,
} from "@bonfida/token-vesting";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConnection } from "../utils/connection";
import { useWallet } from "../utils/wallet";
import WalletConnect from "../components/WalletConnect";
import { checkTextFieldNumberInput, timeConverter } from "../utils/utils";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Spin from "../components/Spin";
import { PublicKey, Transaction } from "@solana/web3.js";
import { sendTransaction } from "../utils/send";
import { notify } from "../utils/notifications";
import Emoji from "../components/Emoji";
import CopyableDisplay from "../components/CopyableDisplay";
import SeedModal from "../components/SeedModal";
import HelpIcon from "@material-ui/icons/Help";
import MouseOverPopOver from "../components/MouseOverPopOver";
import { checkDestinationMint } from "../utils/utils";
import BN from "bn.js";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";

const useStyles = makeStyles({
  h2: {
    fontSize: "max(2vw, 40px)",
    fontWeight: 400,
    color: "white",
    opacity: 0.8,
    marginTop: "2%",
    marginBottom: "2%",
  },
  toggleButton: {
    color: "white",
  },
  text: {
    fontSize: 24,
    color: "white",
  },
  button: {
    color: "white",
    background: "transparent",
    width: "auto",
    borderRadius: 5,
    height: "50px",
    border: "2px solid",
    borderColor: "#5C1864",
    fontSize: 20,
    padding: 20,
    marginTop: "1%",
  },
  submitBut: {
    color: "white",
    fontWeight: 600,
    background: "linear-gradient(213.67deg, #7171ff -3.51%, #DC1FFF 99.6%)",
    borderRadius: 5,
    height: "50px",
    fontSize: 30,
    padding: 30,
    margin: "5%",
  },
  input: {
    color: "white",
  },
  deleteIcon: {
    color: "white",
    marginTop: 30,
    cursor: "pointer",
  },
  datePicker: {
    color: "white",
    width: 250,
  },
  graphContainer: {
    height: "max(50vh, 200px)",
    width: "80%",
  },
  textClassName: {
    color: "white",
    opacity: 0.7,
  },
  svg: {
    marginTop: 10,
    color: "white",
  },
  legacyModeHelp: {
    marginTop: 5,
    color: "white",
  }
});

interface uiSchedule {
  amount: number;
  releaseTime: Date;
}

console.log(`Vesting programId ${TOKEN_VESTING_PROGRAM_ID.toBase58()}`);

const LockPage = () => {
  const classes = useStyles();
  // Legacy mode: use auxillary token accounts
  const [legacyMode, setLegacyMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const connection = useConnection();
  const { wallet, connected } = useWallet();
  const [schedules, setSchedules] = useState<uiSchedule[]>([]);
  const [sourceAddress, setSourceAddress] = useState<string | null>(null);
  const [destinationAddress, setDestinationAddress] =
    useState<string | null>(null);
  const [mintAddress, setMintAddress] = useState<string | null>(null);
  const [seed, setSeed] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);

  const graph = React.useMemo(
    () => (
      <GraphSection
        data={schedules
          .sort((a, b) => a.releaseTime.getTime() - b.releaseTime.getTime())
          .map((s) => {
            return { amount: s.amount, time: timeConverter(s.releaseTime) };
          })}
        xKey="time"
        yKey="amount"
      />
    ),
    [schedules]
  );

  const onChangeMint = (e) => {
    const trimmed = e.target.value.trim();
    setMintAddress(trimmed);
  };

  const onChangeDestinationAddress = (e) => {
    const trimmed = e.target.value.trim();
    setDestinationAddress(trimmed);
  };

  const onChangeSourceAddress = (e) => {
    const trimmed = e.target.value.trim();
    setSourceAddress(trimmed);
  };

  const handleDateChange = (i: number) => (date: Date | null) => {
    let _schedules = [...schedules];
    if (!date) {
      return;
    }
    _schedules[i].releaseTime = date;
    setSchedules(_schedules);
  };

  const handleAmountChange = (i: number) => (e) => {
    const { valid, value } = checkTextFieldNumberInput(e);
    if (!valid) {
      return;
    }
    let _schedules = [...schedules];
    _schedules[i].amount = value;
    setSchedules(_schedules);
  };

  const handleRemoveSchedule = (i: number) => () => {
    const _schedules = schedules
      .slice(0, i)
      .concat(schedules.slice(i + 1, schedules.length));
    setSchedules(_schedules);
  };

  const addSchedule = () => {
    const _schedules = [...schedules];
    _schedules.push({
      amount: 1,
      releaseTime: new Date("2024-04-02T00:00:00"),
    });
    setSchedules(_schedules);
  };

  const submit = async () => {
    if ((!mintAddress || (legacyMode && !sourceAddress) || !destinationAddress)) {
      return;
    }
    try {
      setLoading(true);

      const _seed = generateRandomSeed();
      const _mintAddress = new PublicKey(mintAddress);

      let _sourceTokenAddress: PublicKey;
      let _destinationTokenAddress: PublicKey;
      let _destinationWallet: PublicKey | undefined;

      if (legacyMode) {
        _sourceTokenAddress = new PublicKey(sourceAddress!);
        _destinationTokenAddress = new PublicKey(destinationAddress);

        const isCorrectSourceMint = await checkDestinationMint(
          connection,
          _mintAddress,
          _sourceTokenAddress
        );

        const isCorrectDestinationMint = await checkDestinationMint(
          connection,
          _mintAddress,
          _destinationTokenAddress
        );
        if (!isCorrectSourceMint || !isCorrectDestinationMint) {
          notify({
            message: "Mints do not match",
            variant: "error",
          });
          return;
        }

      } else {
        _sourceTokenAddress = await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          _mintAddress,
          wallet.publicKey
        );

        _destinationWallet = new PublicKey(destinationAddress);
        _destinationTokenAddress = await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          _mintAddress,
          _destinationWallet
        );
      }

      const decimals = (await connection.getTokenSupply(_mintAddress)).value
        .decimals;
      const _schedules: Schedule[] = schedules.map((s) => {
        const releaseTime = new Numberu64(
          Math.floor(s.releaseTime.getTime() / 1000)
        );
        const tokenAmountBN = new BN(s.amount.toString());
        const decimalsBN = new BN(Math.pow(10, decimals).toString());
        const multBN = tokenAmountBN.mul(decimalsBN);
        const amount = new Numberu64(multBN.toArrayLike(Buffer));
        return new Schedule(releaseTime, amount);
      });

      const instructions = await create(
        connection,
        TOKEN_VESTING_PROGRAM_ID,
        Buffer.from(_seed, "hex"),
        wallet.publicKey,
        wallet.publicKey,
        _sourceTokenAddress,
        _destinationTokenAddress,
        _mintAddress,
        _schedules
      );

      const tx = new Transaction().add(...instructions);

      const token = new Token(connection, _mintAddress, TOKEN_PROGRAM_ID, wallet);

      if (!legacyMode) {
        // If associated token account for destination wallet does not exist
        // add a create instruction to the transaction

        try {
          await token.getAccountInfo(_destinationTokenAddress);
        } catch (err) {
          const FAILED_TO_FIND_ACCOUNT = "Failed to find account";
          const INVALID_ACCOUNT_OWNER = "Invalid account owner";

          if (err instanceof Error && (
              err.message === FAILED_TO_FIND_ACCOUNT ||
              err.message === INVALID_ACCOUNT_OWNER
          )) {
            tx.add(
              Token.createAssociatedTokenAccountInstruction(
                ASSOCIATED_TOKEN_PROGRAM_ID,
                TOKEN_PROGRAM_ID,
                _mintAddress,
                _destinationTokenAddress,
                _destinationWallet!,
                wallet.publicKey
              ),
            );
          }
        }
      }

      await sendTransaction({
        transaction: tx,
        wallet: wallet,
        connection: connection,
      });
      setSeed(_seed);
      notify({
        message: "Tokens locked!",
        variant: "success",
      });
      setOpenModal(true);
    } catch (err) {
      console.warn(`Error -${err}`);
      notify({
        message: `Error locking tokens - ${err}`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  let canSubmit =
    schedules.length > 0 && mintAddress && destinationAddress && (!legacyMode || sourceAddress);

  if (!connected) {
    return (
      <>
        <Grid container justify="center">
          <WalletConnect />
        </Grid>
      </>
    );
  }
  return (
    <>
      <Typography align="center" variant="h1" className={classes.h2}>
        Token Information
      </Typography>
      <div>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={5}
        >
          <Grid item>
            {/* Mint Address */}
            <FormControl>
              <Grid container justify="center">
                <Grid item>
                  <InputLabel shrink>Mint Address:</InputLabel>
                  <TextField
                    placeholder="Mint Address"
                    value={mintAddress}
                    onChange={onChangeMint}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
                <Grid item>
                  <MouseOverPopOver
                    popOverText={
                      <>
                        Mint address of the tokens to lock, e.g
                        <br />
                        FIDA: EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp
                      </>
                    }
                    textClassName={classes.textClassName}
                  >
                    <HelpIcon className={classes.svg} />
                  </MouseOverPopOver>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>

            {/* Source Address */}
            {
              legacyMode
                ? <Grid item>
                    <FormControl>
                      <Grid container justify="center">
                        <Grid item>
                          <InputLabel shrink>
                            Source Token Address:
                          </InputLabel>
                          <TextField
                            placeholder="Source Token Address"
                            value={sourceAddress}
                            onChange={onChangeSourceAddress}
                            InputProps={{
                              className: classes.input,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <MouseOverPopOver
                            popOverText={
                              <>
                                ⚠️ This cannot be a SOL address ⚠️ <br />
                                Token address of the source i.e from where <br />
                                the locked tokens will be deducted from.
                              </>
                            }
                            textClassName={classes.textClassName}
                          >
                            <HelpIcon className={classes.svg} />
                          </MouseOverPopOver>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                : undefined
            }

          <Grid item>
            {/* Destination Address */}
            <FormControl>
              <Grid container justify="center">
                <Grid item>
                  <InputLabel shrink>
                  {
                    legacyMode
                      ? "Destination Token Address:"
                      : "Destination wallet:"
                  }
                  </InputLabel>
                  <TextField
                    placeholder={
                      legacyMode
                        ? "Destination Token Address"
                        : "Destination wallet"
                    }
                    value={destinationAddress}
                    onChange={onChangeDestinationAddress}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
                <Grid item>
                  <MouseOverPopOver
                    popOverText={
                      legacyMode
                        ? <>
                          ⚠️ This cannot be a SOL address ⚠️ <br />
                          Token address of the destination i.e address <br /> to
                          which the tokens will be credited once unlocked.
                        </>
                        : <>
                          Solana wallet address of the destination i.e address <br /> to
                          which the tokens will be credited once unlocked. <br />
                          Associated token account will be auto-created if not <br />
                          already present.
                        </>
                    }
                    textClassName={classes.textClassName}
                  >
                    <HelpIcon className={classes.svg} />
                  </MouseOverPopOver>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item>
            {/* Legacy mode toggle */}
            <FormControl>
              <Grid container justify="center">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={legacyMode}
                        onChange={() => {setLegacyMode(!legacyMode)}}
                        name="Legacy mode"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={
                      <Typography variant="button" className={classes.toggleButton}>
                        Legacy mode
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <MouseOverPopOver
                    popOverText={
                      <>
                        Use auxillary token accounts for vesting.<br />
                        Auxillary accounts must be created before <br />
                        using the vesting program.
                      </>
                    }
                    textClassName={classes.textClassName}
                  >
                    <HelpIcon className={classes.legacyModeHelp} />
                  </MouseOverPopOver>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Typography align="center" variant="h2" className={classes.h2}>
            Schedules
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {schedules.map((s, i) => {
              return (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={5}
                >
                  <Grid item>
                    <InputLabel>Amount</InputLabel>
                    <TextField
                      style={{ width: 200 }}
                      value={schedules[i].amount}
                      placeholder="Amount"
                      onChange={handleAmountChange(i)}
                    />
                  </Grid>
                  <Grid item>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      margin="normal"
                      label="Unlock Date"
                      format="MM/dd/yyyy"
                      value={s.releaseTime}
                      onChange={handleDateChange(i)}
                      className={classes.datePicker}
                      // onBlur={onBlurDate(i)}
                    />
                  </Grid>
                  <Grid item>
                    <DeleteIcon
                      className={classes.deleteIcon}
                      onClick={handleRemoveSchedule(i)}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </MuiPickersUtilsProvider>
          <Button className={classes.button} onClick={addSchedule}>
            <span style={{ color: "white" }}>Add Schedule</span>
          </Button>
        </Grid>
      </div>
      <Grid container justify="center">
        <div className={classes.graphContainer}>{graph}</div>
      </Grid>
      <div>
        <Grid container justify="center">
          <Button
            disabled={!canSubmit || loading}
            onClick={submit}
            className={classes.submitBut}
          >
            {loading ? (
              <Spin size={20} />
            ) : (
              <span style={{ color: "white" }}>Submit</span>
            )}
          </Button>
        </Grid>
      </div>
      {seed && (
        <div>
          <Typography className={classes.text} variant="body1" align="center">
            <Emoji emoji="🚨" /> Vesting contract seed <Emoji emoji="🚨" />
          </Typography>
          <Typography className={classes.text} variant="body1" align="center">
            {seed} <CopyableDisplay text={seed} />
          </Typography>
        </div>
      )}
      <SeedModal seed={seed} open={openModal} setOpen={setOpenModal} />
    </>
  );
};

export default LockPage;
