import React, { useEffect } from "react";
import { HashRouter, Route } from "react-router-dom";
import { useSnackbar } from "notistack";
import SnackbarUtils from "./utils/SnackbarUtils";
import NavigationFrame from "./components/NavigationFrame";
import LockPage from "./pages/LockPage";
import UnlockPage from "./pages/UnlockPage";
import ChangeDestinationPage from "./pages/ChangeDestinationPage";
import { useLocalStorageState } from "./utils/utils";

export default function Routes() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar);
  }, [enqueueSnackbar, closeSnackbar]);
  const [showLock] = useLocalStorageState("showLock", false);
  return (
    <HashRouter>
      <NavigationFrame>
        {/* Hide lock page */}
        {showLock && <Route exact path="/lock" component={LockPage} />}
        <Route exact path="/" component={UnlockPage} />
        <Route
          exact
          path="/change-destination"
          component={ChangeDestinationPage}
        />
      </NavigationFrame>
    </HashRouter>
  );
}
