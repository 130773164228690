import React from "react";
import App from "./App";
import Routes from "./Routes";

const BonfidaTokenVesting = () => {
  return (
    <App>
      <Routes />
    </App>
  );
};

export default BonfidaTokenVesting;
