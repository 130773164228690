import React, { useState } from "react";
import Modal from "./Modal";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { notify } from "../utils/notifications";
import CopyableDisplay from "./CopyableDisplay";

const useStyles = makeStyles({
  input: {
    color: "white",
  },
  inputLabel: {
    marginTop: 10,
    marginBottom: 10,
  },
  container: {
    color: "white",
    padding: 10,
    background: "black",
  },
  modalTitle: {
    fontSize: 20,
    color: "white",
    opacity: 0.8,
  },
  seed: {
    fontSize: 18,
    color: "white",
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    fontSize: 18,
    color: "white",
    opacity: 0.75,
  },
  button: {
    color: "white",
    background: "transparent",
    width: "auto",
    borderRadius: 5,
    height: "50px",
    border: "2px solid",
    borderColor: "#5C1864",
    fontSize: 20,
    padding: 20,
  },

  checkbox: {
    color: "red",
    "&$checked": {
      color: "red",
    },
  },
  checked: {},
});

const ModalChildren = ({
  seed,
  setOpen,
}: {
  seed: string;
  setOpen: (arg: boolean) => void;
}) => {
  const classes = useStyles();
  const [inputSeed, setInputSeed] = useState<null | string>(null);
  const [step, setStep] = useState<1 | 0>(0);
  const [checked, setChecked] = useState(false);

  const onChangeInputSeed = (e) => {
    setInputSeed(e.target.value.trim());
  };

  const onClick = () => {
    if (inputSeed !== seed) {
      notify({
        message: "Incorrect seed",
        variant: "error",
      });
      return;
    }
    setOpen(false);
  };

  if (step === 0) {
    return (
      <div style={{ margin: 20, padding: 20 }}>
        <Typography
          className={classes.modalTitle}
          variant="body1"
          align="center"
        >
          Your vesting seed
        </Typography>
        <Typography className={classes.seed} variant="body1" align="center">
          {seed}
          <CopyableDisplay text={seed} />
        </Typography>
        <Typography className={classes.text} variant="body1" align="center">
          Make sure to save your token vesting seed in a safe place. If you
          loose it you will not be able to claim your locked tokens
        </Typography>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Checkbox
              checked={checked}
              classes={{
                root: classes.checkbox,
                checked: classes.checked,
              }}
              onChange={() => setChecked((prev) => !prev)}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.text} variant="body1">
              I have saved the seed in a safe place
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Button
            className={classes.button}
            disabled={!checked}
            onClick={() => setStep(1)}
          >
            <span style={{ color: "white" }}>Next</span>
          </Button>
        </Grid>
      </div>
    );
  }
  if (step === 1) {
    return (
      <div style={{ margin: 20, padding: 20 }}>
        <TextField
          placeholder="Confirm your seed"
          value={inputSeed}
          onChange={onChangeInputSeed}
          InputProps={{
            className: classes.input,
          }}
        />

        <Grid
          container
          style={{ width: "100%", marginTop: 20 }}
          justify="center"
          alignItems="center"
          direction="row"
          spacing={5}
        >
          <Grid item>
            <Button className={classes.button} onClick={() => setStep(0)}>
              <span style={{ color: "white" }}>Back</span>
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              disabled={inputSeed !== seed}
              onClick={onClick}
            >
              <span style={{ color: "white" }}>Close</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
  return null;
};

const SeedModal = ({
  open,
  setOpen,
  seed,
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  seed: string;
}) => {
  const classes = useStyles();
  return (
    <Modal setOpen={setOpen} open={open} disableBackdropClick={true}>
      <div className={classes.container}>
        <ModalChildren seed={seed} setOpen={setOpen} />
      </div>
    </Modal>
  );
};

export default SeedModal;
